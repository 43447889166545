import React from "react"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form";
import logo from "./../../assets/images/logo.svg"
import footer from "./../../assets/images/footer.png"
import "./../../assets/sass/menu/login.sass"

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    };
    return (
        <main>
            <div className="login">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Link to={"/"}><img src={logo} alt="Logo" / ></Link>
                    <h1>Connexion</h1>
                    <div className="form-group">
                        <label>
                            <input type="text" defaultValue="" placeholder="email@gmail.com" {...register("user")} />
                        </label>
                        <label>
                            <input type="password" placeholder="Mot de passe" {...register("password", { required: true })} />
                            {errors.password && <p className="error">Aucun mot de passe.</p>}
                        </label>
                    </div>
                    <div className="option">
                        <label>
                            <input type="checkbox" id="remember" />
                            <span>Se souvenir de moi</span>
                        </label>
                        <Link to="/menu/login">Mot de passe oublié ?</Link>
                    </div>
                    <input type="submit" value="OK"/>
                </form>
                <img src={footer} alt="footer" />
            </div>
        </main>
    )
}

export default Login
