import * as React from "react"
import { Link } from "react-router-dom"
import logo from "./../assets/images/logo_white.png"
import facebook from "./../assets/images/facebook.png"
import instagram from "./../assets/images/instagram.png"
import linkedin from "./../assets/images/linkedin.png"
import "./../assets/sass/footer.sass"


const Header = () => {
    return (
        <div className="footer">
            <img src={logo} className="logo" alt="logo" />
            <div className="menu">
                <Link to="/"><p>Privacy</p></Link>
                <Link to="/"><p>Terms & Conditions</p></Link>
                <Link to="/"><p>Contact</p></Link>
            </div>
            <div className="break"></div>
            <div className="social">
                <p>© 2022  APY, Tous droits réservés</p>
                <div className="social-icons">
                    <Link to="/"><img src={facebook} alt="Facebook" /></Link>
                    <Link to="/"><img src={instagram} alt="Instagram" /></Link>
                    <Link to="/"><img src={linkedin} alt="linkedin" /></Link>
                </div>
            </div>
        </div>
    )
}

export default Header
