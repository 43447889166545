import * as React from "react"
import { Link } from "react-router-dom"
import Header from "../component/Header"
import Footer from "../component/Footer"
import qrcode from "./../assets/images/qr_code.png"
import demoMobile from "./../assets/images/demo_mobile.png"
import navigateurs from "./../assets/images/navigateurs.svg"
import motif1 from "./../assets/images/motif_home_1.png"
import motif2 from "./../assets/images/motif_home_2.png"
import demoDashboard from "./../assets/images/demo_dashboard.png"
import formIcon from "./../assets/images/form_icon.png"
import Select from 'react-select';
import "./../assets/sass/home/main.sass"


const IndexPage = () => {

    const scroll = (e, id) => {
        const elem = document.querySelector(id)
        if (elem) {
            e.preventDefault();
            elem.scrollIntoView({
                behavior: 'smooth'
            });
        } else {
            setTimeout(() => {
                document.querySelector(id).scrollIntoView({
                    behavior: 'smooth'
                });
            }, 500)
        }
    }
    

    return (
        <main>
            <Header/>
            <main>
                <div className="start">
                    <div className="container">
                        <h2>Optez Pour L’affichage Numérique De Votre Menu</h2>
                        <p>Votre menu peut-être consulté depuis n’importe quel appareil mobile, sans avoir à télécharger d’application !  Vos clients accéderont instantanément à votre menu numérique en scannant le QR Code avec l’appareil photo de leur téléphone.</p>
                        <div>
                            <button onClick={(e) => scroll(e, '#form')}>Commmencez gratuitement</button>
                            <p>Pas de carte de crédit nécessaire</p>
                        </div>
                    </div>
                    <img src={qrcode} alt="ilutration qr code" />
                </div>
                <div className="Une-image-vaut-mille-mots">
                    <img src={demoMobile} alt="exemple version mobile"/>
                    <div className="container">
                        <h4>Facile et Rapide</h4>
                        <h2>Une image vaut mille mots</h2>
                        <p>C’est bien connu : les clients commencent à manger avec les yeux ! Offrez-leur la possibilité de découvrir vos plats avec de jolies photos. Notre interface rapide,  esthétique et sécurisée leur permettra de passer leur commande en toute simplicité.</p>
                        <h4 className="compatible">Compatible Avec</h4>
                        <img className="compatible-img" src={navigateurs} alt="navigateurs" />
                    </div>
                    <img src={motif1} className="motif1" alt="motif 1" />
                </div>
                <div className="Une-Gestion-Plus-Efficace-Des-Commandes">
                    <div className="container">
                        <h2>Une Gestion Plus Efficace Des Commandes</h2>
                        <p>Suspendez instantanément les plats ou les articles dont vous êtes à court. Optimisez le temps pour les prises de commande en les rendant modulables et modifiables à tout moment</p>
                        <div className="row">
                            <p>Visibilité Web optimisée</p>
                            <p>Tour de table plus rapide</p>
                            <p>Dynamique et modifiable</p>
                        </div>
                    </div>
                    <img src={demoDashboard} alt="exemple dashboard" />
                    <img src={motif2} className="motif2" alt="motif 2" />
                </div>
                <form className="form" id="form">
                    <img src={formIcon} alt="form icon" />
                    <h2>Demandez Votre Menu Numerique</h2>
                    <p>Remplissez le formulaire pour essayer APY dans votre établissement dés maintenant</p>
                    <div className="form-group">
                        <div className="field">
                            <p>Error: missing !</p>
                            <label htmlFor="name">Prénom / Nom</label>
                            <input type="text" id="name" placeholder="Votre nom" />
                        </div>
                        <div className="field">
                            <label htmlFor="name_restaurant">Nom de l'établissement</label>
                            <input type="text" id="name_restaurant" placeholder="le nom de l'établissement" />
                        </div>
                        <div className="field">
                            <label htmlFor="email">Email*</label>
                            <input type="text" id="email" placeholder="Votre email" />
                        </div>
                        <div className="field">
                            <label htmlFor="phone">Tel</label>
                            <input type="text" id="phone" placeholder="Votre numéro" />
                        </div>
                        <div className="field">
                            <label htmlFor="city">Vile / Code Postal</label>
                            <input type="text" id="city" placeholder="Ville du restaurant" />
                        </div>
                        <div className="field">
                            <label htmlFor="type">Type d'enseigne</label>
                            <Select
                                id="type"
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={{ value: "restaurateur", label: "Je suis restaurateur" }}
                                isDisabled={true}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={false}
                                name="type"
                                options={[
                                    {value: "restaurateur", label: "Je suis restaurateur"},
                                ]}
                            />
                        </div>
                    </div>
                    <input className="submit" type="submit" value="Envoyer" />
                </form>
            </main>
            <Footer/>
        </main>
    )
}

export default IndexPage
