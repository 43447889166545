import React from "react"
import Recoil from 'recoil';
import { menuIdState } from '../../store/menu';
import Dashboard from "../../component/menu/Dashboard";
import "./../../assets/sass/menu/main.sass"

const Menu = () => {
    const [menuId] = Recoil.useRecoilState(menuIdState);

    return (
        <main className="menu">
            <Dashboard />
        </main>
    )
}

export default Menu
